.container {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    background: #f4f4f9;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.quiz-container {
    margin-top: 20px;
}

.question {
    margin-bottom: 15px;
}

.option-label {
    margin-left: 10px;
    font-size: 16px;
}

input[type="radio"] {
    margin-right: 5px;
}

.button {
    padding: 8px 16px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

.button:hover {
    background-color: #0056b3;
}

.correct-answer {
    color: green;
}

.wrong-answer {
    color: red;
}

.phase-indicator {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.percentage-display {
    font-weight: bold;
    color: black;
}
